import React, { useState } from "react";
import { HeroSection, MainSection, FooterSection } from "./sections";
import styled from "styled-components";

const Story = () => {
  const [step, setStep] = useState<number>(0);
  const [order, setOrder] = useState<string>("alphabet");

  const handleStepChange = (stepInput: number) => {
    setStep(stepInput);
  };

  const handleOrderChange = (orderInput: string) => {
    setOrder(orderInput);
  };

  return (
    <>
      <HeroSection />
      <MainSection
        step={step}
        order={order}
        setGlobalStep={handleStepChange}
        setOrder={handleOrderChange}
      />
      <FooterSection />
    </>
  );
};

export default Story;

import * as React from "react";
import styled from "styled-components";
import { H0, H4, sizes, colors } from "../../models";
import {
  GridContainer,
  HeroTextWrapper,
  MidTextWrapper,
  BigDistance,
  ScreenHeight,
  ExternalLink,
} from "../basics";
import { CloroMap } from "../graphic";
import { ReactComponent as MapSvg } from "../../svg/map_berlin.svg";
import { ReactComponent as StartArrowSvg } from "../../svg/icon_start-arrow.svg";

const HeroSectionWrapper = styled.div`
  width: 100%;
  background: ${colors.backgroundGradient};
`;

const TitleWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  ${H0};
  color: ${colors.backgroundLight};
  text-shadow: 2px -2px 2px #ffffff, -1px 4px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: ${sizes.fine}px;
  text-align: center;
`;

const Subtitle = styled.div`
  ${H4};
  text-align: center;
  max-width: 600px;
`;

const IntroText = styled.div`
  ${H4};
  margin-bottom: ${sizes.fine}px;
`;

const MapImageWrapper = styled.div`
  margin: 0 auto ${sizes.hefty}px auto;
  max-width: 800px;
`;

const ArrowImageWrapper = styled.div`
  margin: ${sizes.fine}px 0;
`;

const HeroSection = () => {
  return (
    <HeroSectionWrapper>
      <GridContainer>
        <HeroTextWrapper>
          <TitleWrapper>
            <Title>
              Berliner<br></br>Gedenk&#8203;tafeln
            </Title>
            <Subtitle>
              was über 3000 Tafeln über die Erinnerungskultur der Stadt verraten
            </Subtitle>
            <ArrowImageWrapper>
              <StartArrowSvg width={"75px"} height={"75px"} />
            </ArrowImageWrapper>
          </TitleWrapper>
        </HeroTextWrapper>
        <MidTextWrapper>
          <BigDistance />
          <IntroText>
            <p>
              Über 3000 Gedenktafeln und -zeichen erinnern in Berlin an
              besondere Personen, Orte und Ereignisse aus der Stadtgeschichte.
              Alleine in Berlin Mitte gibt es 944 Tafeln, die in der
              Datensammlung des Projekts{" "}
              <ExternalLink
                href="https://www.gedenktafeln-in-berlin.de/"
                target="_blank"
              >
                Gedenktafeln in Berlin
              </ExternalLink>{" "}
              bis 2016 erfasst wurden. Erfahre wie viele Tafeln und Zeichen in
              den jeweiligen Berliner Bezirken angebracht sind, indem du einen
              Bezirk auf der Karte anwählst:
            </p>
            <MapImageWrapper>
              <CloroMap />
            </MapImageWrapper>
            <p>
              Viele Tafeln erinnern an den Kampf gegen den Nationalsozialismus
              und dessen Verbrechen. Es werden Held*innen geehrt und Opfer
              erinnert, wobei nicht jede Person oder jedes Ereignis unumstritten
              ist. Mithilfe der folgendenen Daten-Geschichte erfährst du mehr
              darüber wem und was Berlin gedenkt. Die Daten dazu stammen vom{" "}
              <ExternalLink
                href="https://daten.berlin.de/datensaetze/liste-der-gedenktafeln-berlin"
                target="_blank"
              >
                Open Data Portal der Stadt Berlin
              </ExternalLink>{" "}
              und wurden mit eigenen Recherchen angereichert. Mehr zur Methodik
              erfährst du am Ende der Seite.
            </p>
          </IntroText>
          <BigDistance />
        </MidTextWrapper>
      </GridContainer>
    </HeroSectionWrapper>
  );
};

export default HeroSection;

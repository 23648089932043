import React, { useRef, useState, useEffect } from "react";
import useMeasure from "react-use-measure";
import { gridMargin, breakpoints, colors } from "../../models";
import { Tooltip, setRectChartTooltipContent } from "./tooltip";
import useWindowDimensions from "../../util";
import { getColor, calculateOptimalRectHeight } from "./get-styles";
import { orderData } from "./order-data";

import styled, { keyframes, css } from "styled-components";
import { Stage, Layer, Rect } from "react-konva";
import * as d3 from "d3";

const GraphicContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const fadeScreenOut = keyframes`
  0% {opacity: 1}
  100% {opacity: 0}
`;

const EmptyScreen = styled.div<any>`
  background-color: ${colors.backgroundDark};
  position: absolute;
  margin-top: 30px;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: ${(props) => (props.visible ? "block" : "none")};
  animation-name: ${(props) => (props.visible ? fadeScreenOut : "")};
  animation-duration: 1.5s;
`;

const RectChart = ({
  step,
  order,
  data,
  containerPosition,
  setExploreTool,
  setSelectedData,
  setData,
}: any) => {
  const [emptyScreenVisible, setEmptyScreenVisible] = useState<boolean>(false);
  const [rectColor, setRectColor] = useState<string[]>([]);
  const [hoverRect, setHoverRect] = useState<any>(undefined);
  const [tooltip, setTooltip] = useState<any>({
    show: false,
    x: 0,
    y: 0,
    text: undefined,
    alignment: "",
  });
  const { height, width } = useWindowDimensions();
  const [updateSize, bounds] = useMeasure();
  const canvasWidth: number = bounds.width;
  const canvasHeight: number = bounds.height;

  useEffect(() => {
    fetch("data-clean.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data);
        setRectColor(
          data.map((d: any) => {
            return getColor(parseInt(step), d);
          })
        );
      });
  }, []);

  useEffect(() => {
    if (!data) return;
    setEmptyScreenVisible(true);
    setTimeout(() => setEmptyScreenVisible(false), 1500);
    const sortedData = orderData(order, data);
    setData(sortedData);
  }, [order]);

  useEffect(() => {
    if (!data) return;
    setRectColor(
      data.map((d: any) => {
        return getColor(parseInt(step), d);
      })
    );
  }, [step]);

  const handleMouseOver = (
    event: any,
    data: any,
    attribute: any,
    rectWidth: number,
    rectHeight: number
  ) => {
    setHoverRect({
      x: Math.round(attribute.x * 100) / 100 - rectWidth * 0.3,
      y: Math.round(attribute.y * 100) / 100 - rectHeight * 0.3,
      width: rectWidth * 1.6,
      height: rectHeight * 1.6,
      fill: attribute.fill,
    });
    setTooltip({
      show: true,
      x:
        event.evt.clientX -
        containerPosition.current.getBoundingClientRect().left,
      y:
        event.evt.clientY -
        containerPosition.current.getBoundingClientRect().top,
      text: setRectChartTooltipContent(data["Name"]),
      alignment: event.evt.clientY < 200 ? "below" : "above",
    });
  };

  const handleMouseOut = () => {
    setHoverRect(undefined);
    setTooltip({
      show: false,
      x: 0,
      y: 0,
      text: undefined,
      alignment: "",
    });
  };

  const createRects = (data: any) => {
    const marginLeftScale = d3
      .scaleThreshold()
      .domain([
        breakpoints.tabletL,
        breakpoints.desktopS,
        breakpoints.desktopL,
        breakpoints.desktopXL,
      ])
      .range([
        gridMargin.smartphone,
        gridMargin.tabletL,
        gridMargin.desktopS,
        gridMargin.desktopL,
        gridMargin.desktopXL,
      ]);
    const canvasPadding = 5;
    const gridMarginLeft = marginLeftScale(width);
    const margins = {
      top:
        width >= breakpoints.tabletL ? gridMarginLeft + canvasPadding + 25 : 60,
      bottom: gridMarginLeft,
      left: 1 + canvasPadding,
      right: 1 + canvasPadding,
    };
    const chartWidth = canvasWidth - margins.left - margins.right;
    const chartHeight = canvasHeight - margins.top - margins.bottom;
    const rectRatio = 15 / 12;
    const numberOfIcons = data.length;
    const rectHeight = calculateOptimalRectHeight(
      chartWidth,
      chartHeight,
      numberOfIcons,
      rectRatio
    );
    const rectWidth = rectHeight * rectRatio;
    const padding = 2;
    const squaresInRow = Math.floor(chartWidth / rectWidth);

    return (
      <Stage width={canvasWidth} height={canvasHeight}>
        <Layer
          x={margins.left}
          y={margins.top}
          onMouseMove={(e: any) => {
            const attribute = e.target.attrs;
            const currentIndex = parseInt(attribute.id);
            const currentData = data[currentIndex];
            handleMouseOver(e, currentData, attribute, rectWidth, rectHeight);
          }}
          onMouseOut={(e: any) => {
            setHoverRect(undefined);
            handleMouseOut();
          }}
          onClick={(e: any) => {
            setExploreTool(true);
            const attribute = e.target.attrs;
            const currentIndex = parseInt(attribute.id);
            const currentData = data[currentIndex];
            setSelectedData(currentData);
          }}
        >
          {data.map((entryData: any, i: number) => {
            return (
              <Rect
                key={i}
                id={`${i}`}
                x={(i % squaresInRow) * rectWidth}
                y={Math.floor(i / squaresInRow) * rectHeight}
                width={rectWidth - padding}
                height={rectHeight - padding}
                fill={rectColor[i]}
                shadowColor={colors.black}
                shadowBlur={0.3}
                shadowOffset={{ x: -0.5, y: 0.5 }}
                shadowOpacity={0.5}
              />
            );
          })}
          {hoverRect && (
            <>
              <Rect
                x={hoverRect.x}
                y={hoverRect.y}
                width={hoverRect.width}
                height={hoverRect.height}
                listening={false}
                fill={hoverRect.fill}
                shadowColor={colors.black}
                shadowBlur={0.5}
                shadowOffset={{ x: -1, y: 1 }}
                shadowOpacity={0.5}
              />
              <Rect // white highlight
                x={hoverRect.x - 0.3}
                y={hoverRect.y + 0.3}
                width={hoverRect.width}
                height={hoverRect.height}
                listening={false}
                fill={hoverRect.fill}
                shadowColor={colors.white}
                shadowBlur={0.3}
                shadowOffset={{ x: 1, y: -1 }}
                shadowOpacity={0.7}
              />
            </>
          )}
        </Layer>
      </Stage>
    );
  };
  return (
    <GraphicContainer ref={updateSize}>
      <EmptyScreen visible={emptyScreenVisible} />
      {data && createRects(data)}
      <Tooltip
        text={tooltip.text}
        show={tooltip.show}
        position={{ x: tooltip.x, y: tooltip.y }}
        alignment={tooltip.alignment}
      />
    </GraphicContainer>
  );
};

export default RectChart;

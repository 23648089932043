import styled from "styled-components";

import {
  colors,
  sizes,
  breakpoints,
  gridContainer,
  textRightGrid,
  textHeroGrid,
  textMidGrid,
  graphicLeftGrid,
  textExploreToolRightGrid,
} from "../models";

export const GridContainer = styled.div`
  ${gridContainer};
`;

export const RightTextWrapper = styled.div`
  ${textRightGrid};
  pointer-events: none;
`;

export const ExploreToolRightTextWrapper = styled.div`
  ${textExploreToolRightGrid};
`;

export const MidTextWrapper = styled.div`
  ${textMidGrid}
`;

export const HeroTextWrapper = styled.div`
  ${textHeroGrid}
`;

export const GraphicLeftWrapper = styled.div`
  ${graphicLeftGrid};
  position: sticky;
  top: 0;
  height: 100vh;
`;

export const ScreenHeight = styled.div`
  height: 100vh;
`;

export const BigDistance = styled.div`
  height: 50vh;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.blue};
  border-bottom: 2px solid ${colors.blue};
  transition: border-bottom 0.2s ease-out;

  &:hover {
    border-bottom: 0px solid ${colors.blue};
  }
`;

import { useEffect } from "react";

export const useIntersection = (
  ref: any,
  selector: string,
  handler: any,
  options: any
) => {
  useEffect(() => {
    const observers: any = [];

    if (ref.current && typeof IntersectionObserver === "function") {
      const handleIntersect = (idx: number) => (entries: any) => {
        handler(entries[0], idx);
      };

      ref.current
        .querySelectorAll(selector)
        .forEach((node: any, idx: number) => {
          const observer = new IntersectionObserver(
            handleIntersect(idx),
            options
          );
          observer.observe(node);
          observers.push(observer);
        });

      return () => {
        observers.forEach((observer: any) => {
          observer.disconnect();
        });
      };
    }
    return () => {};
  }, [ref.current, options.threshold, options.rootMargin]);
};

export default useIntersection;

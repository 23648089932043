interface Data {}

function sortByYear(a: any, b: any) {
  if (a.jahr === b.jahr) {
    return 0;
  }
  if (a.jahr === "none") {
    return 1;
  }
  if (b.jahr === "none") {
    return -1;
  }
  return a.jahr < b.jahr ? -1 : 1;
}

function sortByPerson(a: any, b: any) {
  if (a.Geschlecht === b.Geschlecht) {
    return 0;
  }
  if (a.Geschlecht === "none") {
    return 1;
  }
  if (b.Geschlecht === "none") {
    return -1;
  }
  if (
    typeof a.Geschlecht !== "string" &&
    !a.Geschlecht.includes("männlich") &&
    !a.Geschlecht.includes("weiblich")
  ) {
    return 1;
  }
  if (
    typeof b.Geschlecht !== "string" &&
    !b.Geschlecht.includes("männlich") &&
    !b.Geschlecht.includes("weiblich")
  ) {
    return -1;
  }

  if (typeof a.Geschlecht !== "string" && typeof b.Geschlecht === "string") {
    if (
      a.Geschlecht.includes("männlich") &&
      !a.Geschlecht.includes("weiblich")
    ) {
      if (b.Geschlecht === "männlich") {
        return 0;
      } else if (b.Geschlecht === "weiblich") {
        return 1;
      }
    } else if (
      !a.Geschlecht.includes("männlich") &&
      a.Geschlecht.includes("weiblich")
    ) {
      if (b.Geschlecht === "weiblich") {
        return 0;
      } else if (b.Geschlecht === "männlich") {
        return -1;
      }
    } else if (
      a.Geschlecht.includes("männlich") &&
      a.Geschlecht.includes("weiblich")
    ) {
      return 1;
    }
  }

  if (typeof a.Geschlecht === "string" && typeof b.Geschlecht !== "string") {
    if (
      b.Geschlecht.includes("männlich") &&
      !b.Geschlecht.includes("weiblich")
    ) {
      if (a.Geschlecht === "männlich") {
        return 0;
      } else if (a.Geschlecht === "weiblich") {
        return -1;
      }
    } else if (
      !b.Geschlecht.includes("männlich") &&
      b.Geschlecht.includes("weiblich")
    ) {
      if (a.Geschlecht === "weiblich") {
        return 0;
      } else if (a.Geschlecht === "männlich") {
        return 1;
      }
    } else if (
      b.Geschlecht.includes("männlich") &&
      b.Geschlecht.includes("weiblich")
    ) {
      return -1;
    }
  }

  if (typeof a.Geschlecht !== "string" && typeof b.Geschlecht !== "string") {
    if (
      a.Geschlecht.includes("männlich") &&
      !a.Geschlecht.includes("weiblich")
    ) {
      if (
        b.Geschlecht.includes("männlich") &&
        !b.Geschlecht.includes("weiblich")
      ) {
        return 0;
      } else if (
        !b.Geschlecht.includes("männlich") &&
        b.Geschlecht.includes("weiblich")
      ) {
        return 1;
      } else if (
        b.Geschlecht.includes("männlich") &&
        b.Geschlecht.includes("weiblich")
      ) {
        return -1;
      }
    }
    if (
      !a.Geschlecht.includes("männlich") &&
      a.Geschlecht.includes("weiblich")
    ) {
      if (
        !b.Geschlecht.includes("männlich") &&
        b.Geschlecht.includes("weiblich")
      ) {
        return 0;
      } else if (
        b.Geschlecht.includes("männlich") &&
        !b.Geschlecht.includes("weiblich")
      ) {
        return -1;
      } else if (
        b.Geschlecht.includes("männlich") &&
        b.Geschlecht.includes("weiblich")
      ) {
        return -1;
      }
    } else if (
      a.Geschlecht.includes("männlich") &&
      a.Geschlecht.includes("weiblich")
    ) {
      if (
        b.Geschlecht.includes("männlich") &&
        b.Geschlecht.includes("weiblich")
      ) {
        return 0;
      } else {
        return 1;
      }
    }
  }

  return a.Geschlecht > b.Geschlecht ? -1 : 1;
}

function sortByBirthYear(a: any, b: any) {
  if (a.Geburtsjahr === b.Geburtsjahr) {
    return 0;
  }
  if (a.Geburtsjahr === "none" || a.Geburtsjahr.length === 0) {
    return 1;
  }
  if (b.Geburtsjahr === "none" || b.Geburtsjahr.length === 0) {
    return -1;
  }

  if (Array.isArray(a.Geburtsjahr) && Array.isArray(b.Geburtsjahr)) {
    const filteredGeburtsjahreA = a.Geburtsjahr.filter(
      (e: any) => e !== "none"
    );
    const filteredGeburtsjahreB = b.Geburtsjahr.filter(
      (e: any) => e !== "none"
    );
    if (
      filteredGeburtsjahreA.length === 1 &&
      filteredGeburtsjahreB.length === 1
    ) {
      return filteredGeburtsjahreA[0] < filteredGeburtsjahreB[0] ? -1 : 1;
    } else if (
      filteredGeburtsjahreA.length === 1 &&
      filteredGeburtsjahreB.length !== 1
    ) {
      return -1;
    } else if (
      filteredGeburtsjahreA.length !== 1 &&
      filteredGeburtsjahreB.length === 1
    ) {
      return 1;
    }
  }

  if (Array.isArray(a.Geburtsjahr) && !Array.isArray(b.Geburtsjahr)) {
    const filteredGeburtsjahreA = a.Geburtsjahr.filter(
      (e: any) => e !== "none"
    );
    if (filteredGeburtsjahreA.length === 1) {
      return filteredGeburtsjahreA[0] < b.Geburtsjahr ? -1 : 1;
    } else if (filteredGeburtsjahreA.length !== 1) {
      return 1;
    }
  }
  if (!Array.isArray(a.Geburtsjahr) && Array.isArray(b.Geburtsjahr)) {
    const filteredGeburtsjahreB = b.Geburtsjahr.filter(
      (e: any) => e !== "none"
    );
    if (filteredGeburtsjahreB.length === 1) {
      return a.Geburtsjahr < filteredGeburtsjahreB[0] ? -1 : 1;
    } else if (filteredGeburtsjahreB.length !== 1) {
      return -1;
    }
  }

  return a.Geburtsjahr < b.Geburtsjahr ? -1 : 1;
}

function sortByDeathYear(a: any, b: any) {
  if (a.Todesjahr === b.Todesjahr) {
    return 0;
  }
  if (a.Todesjahr === "none" || a.Todesjahr.length === 0) {
    return 1;
  }
  if (b.Todesjahr === "none" || b.Todesjahr.length === 0) {
    return -1;
  }

  if (Array.isArray(a.Todesjahr) && Array.isArray(b.Todesjahr)) {
    const filteredTodesjahreA = a.Todesjahr.filter((e: any) => e !== "none");
    const filteredTodesjahreB = b.Todesjahr.filter((e: any) => e !== "none");
    if (filteredTodesjahreA.length === 1 && filteredTodesjahreB.length === 1) {
      return filteredTodesjahreA[0] < filteredTodesjahreB[0] ? -1 : 1;
    } else if (
      filteredTodesjahreA.length === 1 &&
      filteredTodesjahreB.length !== 1
    ) {
      return -1;
    } else if (
      filteredTodesjahreA.length !== 1 &&
      filteredTodesjahreB.length === 1
    ) {
      return 1;
    }
  }

  if (Array.isArray(a.Todesjahr) && !Array.isArray(b.Todesjahr)) {
    const filteredTodesjahreA = a.Todesjahr.filter((e: any) => e !== "none");
    if (filteredTodesjahreA.length === 1) {
      return filteredTodesjahreA[0] < b.Todesjahr ? -1 : 1;
    } else if (filteredTodesjahreA.length !== 1) {
      return 1;
    }
  }
  if (!Array.isArray(a.Todesjahr) && Array.isArray(b.Todesjahr)) {
    const filteredTodesjahreB = b.Todesjahr.filter((e: any) => e !== "none");
    if (filteredTodesjahreB.length === 1) {
      return a.Todesjahr < filteredTodesjahreB[0] ? -1 : 1;
    } else if (filteredTodesjahreB.length !== 1) {
      return -1;
    }
  }

  return a.Todesjahr < b.Todesjahr ? -1 : 1;
}

function sortByAlphabet(a: any, b: any) {
  if (a.Name === b.Name) {
    return 0;
  }
  return a.Name < b.Name ? -1 : 1;
}

export const orderData = (order: string, data: any) => {
  if (order === "person") {
    return data.sort(sortByPerson);
  } else if (order === "geburt") {
    return data.sort(sortByBirthYear);
  } else if (order === "tod") {
    return data.sort(sortByDeathYear);
  } else if (order === "alphabet") {
    return data.sort(sortByAlphabet);
  } else {
    return data;
  }
};

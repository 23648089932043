import { colors } from "../../models";
import * as d3 from "d3";

export const getColor = (step: number, data: any) => {
  const colorScale = d3
    .scaleLinear<string, number>()
    .domain([1400, 1800, 1850, 1900, 1950, 2000, 2022])
    .range([
      colors.seq1,
      colors.seq1,
      colors.seq2,
      colors.seq3,
      colors.seq4,
      colors.seq5,
      colors.seq5,
    ]);

  switch (step) {
    case 0:
      return colors.backgroundLight;
    case 1:
      return data.jahr === 1890 ? colors.blue : colors.backgroundLight;
    case 2:
      return data.Name === "Jüdische Hausbewohnerinnen und -bewohner"
        ? colors.blue
        : colors.backgroundLight;
    case 3:
      return data.swo.includes("Nationalsozialismus") ||
        data.swo.includes("Widerstand gegen den Nationalsozialismus")
        ? colors.blue
        : colors.backgroundLight;
    case 4:
      return data.Name.includes("Fußballroute")
        ? colors.blue
        : colors.backgroundLight;
    case 5:
      return data.entfernt != "none" ? colors.blue : colors.backgroundLight;
    case 6:
      return data.Geschlecht.includes("männlich") ||
        data.Geschlecht.includes("weiblich")
        ? colors.blue
        : colors.backgroundLight;
    case 7:
      if (
        data.Geschlecht === "männlich" ||
        (data.Geschlecht.includes("männlich") &&
          !data.Geschlecht.includes("weiblich"))
      ) {
        return colors.male;
      } else if (
        data.Geschlecht === "weiblich" ||
        (data.Geschlecht.includes("weiblich") &&
          !data.Geschlecht.includes("männlich"))
      ) {
        return colors.female;
      } else if (
        data.Geschlecht.includes("männlich") &&
        data.Geschlecht.includes("weiblich")
      ) {
        return colors.bothGender;
      } else {
        return colors.backgroundLight;
      }
    case 8:
      if (data.Name === "Albert Einstein") {
        return colors.male;
      } else if (data.Name === "Rosa Luxemburg") {
        return colors.female;
      } else if (
        data.Geschlecht === "männlich" ||
        (data.Geschlecht.includes("männlich") &&
          !data.Geschlecht.includes("weiblich"))
      ) {
        return colors.maleLight;
      } else if (
        data.Geschlecht === "weiblich" ||
        (data.Geschlecht.includes("weiblich") &&
          !data.Geschlecht.includes("männlich"))
      ) {
        return colors.femaleLight;
      } else if (
        data.Geschlecht.includes("männlich") &&
        data.Geschlecht.includes("weiblich")
      ) {
        return colors.bothGenderLight;
      } else {
        return colors.backgroundLight;
      }
    case 9:
      if (typeof data.Geburtsjahr === "number") {
        return colorScale(data.Geburtsjahr);
      } else if (Array.isArray(data.Geburtsjahr)) {
        const filteredGeburtsjahre = data.Geburtsjahr.filter(
          (e: any) => e != "none"
        );
        return filteredGeburtsjahre.length === 1 && filteredGeburtsjahre[0] != 0
          ? colorScale(filteredGeburtsjahre[0])
          : colors.backgroundLight;
      } else if (
        data.Geschlecht.includes("weiblich") ||
        data.Geschlecht.includes("männlich")
      ) {
        return colors.backgroundLight;
      } else {
        return colors.backgroundLight;
      }
    case 10:
      if (typeof data.Todesjahr === "number") {
        return colorScale(data.Todesjahr);
      } else if (Array.isArray(data.Todesjahr)) {
        const filteredTodesjahre = data.Todesjahr.filter(
          (e: any) => e != "none"
        );
        return filteredTodesjahre.length === 1 && filteredTodesjahre[0] != 0
          ? colorScale(filteredTodesjahre[0])
          : colors.backgroundLight;
      } else {
        return colors.backgroundLight;
      }
    case 11:
      return colors.backgroundLight;
    case 12:
      return colors.backgroundLight;
  }
};

export const calculateOptimalRectHeight = (
  chartWidth: number,
  chartHeight: number,
  numberOfIcons: number,
  rectRatio: number
) => {
  let rectHeight = Math.ceil(
    Math.sqrt((chartWidth * chartHeight) / (numberOfIcons * rectRatio))
  );
  while (
    numberOfIcons >
    Math.floor(chartWidth / (rectRatio * rectHeight)) *
      Math.floor(chartHeight / rectHeight)
  ) {
    rectHeight = rectHeight - 0.2;
  }
  return rectHeight;
};

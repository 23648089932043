import { createFontSizeScale } from "../util";
import "@fontsource/lora/variable.css";
import "@fontsource/roboto-mono";

export enum sizes {
  hair = 1,
  dblhair = 2,
  bristle = 3,
  micro = 4,
  tiny = 6,
  thin = 8,
  fine = 10,
  little = 14,
  base = 16,
  small = 18,
  petite = 20,
  junior = 24,
  compact = 28,
  twice = 32,
  medium = 36,
  hefty = 40,
  big = 48,
  large = 56,
  wide = 72,
  huge = 90,
  mediumhuge = 154,
  extremehuge = 200,
}

export const colors = {
  transparent: "rgba(0, 0, 0, 0)",
  white: "#FFF",
  whiteTransparent: "rgba(255, 255, 255, 0.8)",
  black: "#1A1A1A",
  noData: "rgba(200, 200, 200, 1)",
  backgroundLight: "#F3F2F2",
  backgroundGradient:
    "linear-gradient(20deg, #E6E6E6 32.21%, #F4F4F4 61.04%, #F9F9F9 70%)",
  backgroundDark: "#E6E6E6",
  blue: "#314785",
  darkBlue: "#00207B",
  gold: "#B5AC95",
  male: "#617CB7",
  maleLight: "#B9C5DF",
  female: "#49B694",
  femaleLight: "#B6E2D4",
  bothGender: "#DD8A4F",
  bothGenderLight: "#FDCA9B",
  seq0: "#DD8A4F",
  seq1: "#DD8A4F",
  seq2: "#49B694",
  seq3: "#66B4C1",
  seq4: "#617CB7",
  seq5: "#581A97",
};

export const fonts = {
  base: "'LoraVariable', serif",
  mono: "'Roboto Mono', monospace",
  weightRegular: "400",
  weightMedium: "500",
};

const baseFontSize = 16;
const { above, below } = createFontSizeScale(baseFontSize, 1.25, {
  above: 6,
  below: 3,
});
const [small, micro, macro] = below.map((s) => `${s}px`);
const [midSize, big, large, huge, gigantic, mega] = above.map((s) => `${s}px`);

/*
  {
    macro: "8.192px",
    micro: "10.24px",
    small: "12.8px",
    base: "16px",
    midSize: "20px",
    big: "25px",
    large: "31.25px",
    huge: "39.0625px",
    gigantic: "48.828125px"
    mega: "61.04px"
  }
*/
export const fontSizes = {
  macro,
  micro,
  small,
  base: `${baseFontSize}px`,
  midSize,
  big,
  large,
  huge,
  gigantic,
  mega,
};

export const lineHeights = {
  small: `1.2em`,
  body: `1.4em`,
};

export const strokes = {
  thin: 0.5,
  base: 1,
  big: 1.5,
};

export const breakpoints = {
  smartphone: 0,
  tabletL: 768,
  desktopS: 1200,
  desktopL: 1440,
  desktopXL: 1920,
};

export const gutterWidths = {
  smartphone: 16,
  tabletL: 24,
  desktopS: 24,
  desktopL: 32,
  desktopXL: 32,
};

export const gridMargin = {
  smartphone: 16,
  tabletL: 16,
  desktopS: 4,
  desktopL: 4,
  desktopXL: 20,
};

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import useMeasure from "react-use-measure";
import * as d3 from "d3";
import { colors, strokes, sizes, fonts, fontSizes } from "../../models";

const ChartContainer = styled.div`
  margin: 0 0 0 0;
  pointer-events: auto;
`;

const LineChart = ({ mode }: any) => {
  const [updateSize, bounds] = useMeasure();
  const [data, setData] = useState<any>(null);
  const [dataGeburt, setDataGeburt] = useState<any>(null);
  const [dataTod, setDataTod] = useState<any>(null);
  const width: number = bounds.width;
  const height: number = bounds.height;

  useEffect(() => {
    fetch("data-clean.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data);
      });
  }, []);

  useEffect(() => {
    if (!data) return;
    let geburtsjahreData: number[] = [];
    let todesjahreData: number[] = [];

    data.map((d: any) => {
      const geschlecht = d.Geschlecht;
      const geburtsjahr = d.Geburtsjahr;
      const todesjahr = d.Todesjahr;
      if (geschlecht.includes("männlich") || geschlecht.includes("weiblich")) {
        if (typeof geburtsjahr === "number") {
          geburtsjahreData.push(geburtsjahr);
        } else if (Array.isArray(geburtsjahr)) {
          const filteredGeburtsjahre = geburtsjahr.filter((e) => e != "none");
          if (
            filteredGeburtsjahre.length === 1 &&
            filteredGeburtsjahre[0] != 0
          ) {
            geburtsjahreData.push(filteredGeburtsjahre[0]);
          }
        }

        if (typeof todesjahr === "number") {
          todesjahreData.push(todesjahr);
        } else if (Array.isArray(todesjahr)) {
          const filteredTodesjahre = todesjahr.filter((e) => e != "none");
          if (filteredTodesjahre.length === 1 && filteredTodesjahre[0] != 0) {
            todesjahreData.push(filteredTodesjahre[0]);
          }
        }
      }
    });
    geburtsjahreData = geburtsjahreData.sort();
    let geburtsjahreRange = Array.from(
      Array(geburtsjahreData[geburtsjahreData.length - 1]).keys()
    );
    geburtsjahreRange = geburtsjahreRange.slice(
      geburtsjahreData[0],
      geburtsjahreRange.length
    );
    const countGeburtsjahre: any = {};
    geburtsjahreData.forEach((element) => {
      countGeburtsjahre[element] = (countGeburtsjahre[element] || 0) + 1;
    });
    let cleanGeburtsjahre: any = {};
    geburtsjahreRange.forEach((year: number) => {
      if (geburtsjahreData.includes(year)) {
        cleanGeburtsjahre[year] = countGeburtsjahre[`${year}`];
      } else {
        cleanGeburtsjahre[year] = 0;
      }
    });
    cleanGeburtsjahre = Object.entries(cleanGeburtsjahre);
    setDataGeburt(cleanGeburtsjahre);

    todesjahreData = todesjahreData.sort();
    let todesjahreRange = Array.from(
      Array(todesjahreData[todesjahreData.length - 1]).keys()
    );
    todesjahreRange = todesjahreRange.slice(
      todesjahreData[0],
      todesjahreRange.length
    );
    const countTodesjahre: any = {};
    todesjahreData.forEach((element) => {
      countTodesjahre[element] = (countTodesjahre[element] || 0) + 1;
    });
    let cleanTodesjahre: any = {};
    geburtsjahreRange.forEach((year: number) => {
      if (todesjahreData.includes(year)) {
        cleanTodesjahre[year] = countTodesjahre[`${year}`];
      } else {
        cleanTodesjahre[year] = 0;
      }
    });
    cleanTodesjahre = Object.entries(cleanTodesjahre);
    setDataTod(cleanTodesjahre);
  }, [data]);

  const createChart = (mode: string) => {
    const lineData = mode === "geburt" ? dataGeburt : dataTod;

    const margins = {
      top: sizes.compact,
      right: sizes.petite,
      bottom: sizes.large,
      left: sizes.compact,
    };
    const chartWidth = width - margins.left - margins.right;
    const chartHeight = height - margins.top - margins.bottom;

    const domainX = mode === "geburt" ? [1400, 2020] : [1400, 2020];
    const domainY = mode === "geburt" ? [0, 40] : [0, 120];

    const gridXData =
      mode === "geburt" ? [1400, 1600, 1800, 2000] : [1400, 1600, 1800, 2000];
    const gridYData =
      mode === "geburt" ? [0, 20, 40, 60] : [0, 30, 60, 90, 120];
    const gridTickHeight = 5;

    const minXGradient = mode === "geburt" ? 1800 : 1800;
    const maxXGradient = mode === "geburt" ? 2000 : 2000;

    const gradientData = [
      { offset: "0%", color: colors.seq1 },
      { offset: "25%", color: colors.seq2 },
      { offset: "50%", color: colors.seq3 },
      { offset: "75%", color: colors.seq4 },
      { offset: "100%", color: colors.seq5 },
    ];

    const line: any = d3
      .line()
      .x((d) => scaleX(d[0]))
      .y((d) => scaleY(d[1]))
      .curve(d3.curveCatmullRom.alpha(0.5));

    const scaleX = d3.scaleLinear().domain(domainX).range([0, chartWidth]);
    const scaleY = d3.scaleLinear().domain(domainY).range([chartHeight, 0]);

    const handleMouseMove = (event: any) => {
      const svgELement: any = d3.select("#line-chart").node();
      const svgPositionX: number = svgELement.getBoundingClientRect().left;
      const valueX = event.clientX - svgPositionX - margins.left;
      const getYear = d3.scaleLinear().domain([0, chartWidth]).range(domainX);
      const currentYear = Math.round(getYear(valueX));
      const yearData = lineData.filter(
        (d: any) => d[0] === `${currentYear}`
      )[0];
      const valueY = scaleY(yearData[1]);

      d3.selectAll(`.hover-elements-${mode}`).style("display", "block");
      d3.selectAll(`.hover-line-${mode}`).attr("x1", valueX).attr("x2", valueX);
      d3.selectAll(`.hover-circle-${mode}`)
        .attr("cx", valueX)
        .attr("cy", valueY);
      d3.selectAll(`.hover-year-${mode}`)
        .attr("x", valueX)
        .text(`${currentYear}`);
      d3.selectAll(`.hover-text-bg-${mode}`).attr("x", valueX - 20);
      d3.selectAll(`.hover-text-value-bg-${mode}`).attr("x", valueX - 45);
      d3.selectAll(`.hover-value-${mode}`)
        .attr("x", valueX)
        .text(`${yearData[1]} Tafel(n)`);
    };

    const handleMouseOut = () => {
      d3.selectAll(".hover-elements").style("display", "block");
    };

    return (
      <g onMouseMove={(e) => handleMouseMove(e)} onMouseOut={handleMouseOut}>
        <rect
          key={"background"}
          x={0}
          y={0}
          width={width}
          height={height}
          fill={colors.backgroundDark}
        />
        <text
          key={"legend-y"}
          x={18}
          y={"1em"}
          fontFamily={fonts.mono}
          fontSize={fontSizes.small}
          fill={colors.blue}
        >
          &#129047;Anzahl an Tafeln
        </text>
        <g transform={`translate(${margins.left},${margins.top})`}>
          <linearGradient
            id={"line-gradient"}
            gradientUnits={"userSpaceOnUse"}
            x1={scaleX(minXGradient)}
            y1={0}
            x2={scaleX(maxXGradient)}
            y2={0}
          >
            {gradientData.map((entry: any, i: number) => {
              return (
                <stop
                  key={`stop-${i}`}
                  offset={entry.offset}
                  stopColor={entry.color}
                />
              );
            })}
          </linearGradient>
          <text
            key={"legend-x"}
            x={chartWidth / 2}
            y={chartHeight + gridTickHeight + 35}
            textAnchor={"middle"}
            fontFamily={fonts.mono}
            fontSize={fontSizes.small}
            fill={colors.blue}
          >
            {mode === "geburt" ? "Geburtsjahr" : "Todesjahr"}
          </text>

          <path
            d={line(lineData)}
            fill={"none"}
            stroke={"url(#line-gradient)"}
            strokeWidth={2}
          />

          {gridXData.map((entry: number) => {
            return (
              <g>
                <line
                  key={`line-path-${entry}`}
                  x1={scaleX(entry)}
                  y1={chartHeight}
                  x2={scaleX(entry)}
                  y2={chartHeight + gridTickHeight}
                  stroke={colors.blue}
                  strokeWidth={1}
                />
                <text
                  key={`chart-text-${entry}`}
                  x={scaleX(entry)}
                  y={chartHeight + gridTickHeight + 2}
                  dy={"1em"}
                  textAnchor={"middle"}
                  fontFamily={fonts.mono}
                  fontSize={fontSizes.small}
                  fill={colors.blue}
                >
                  {entry}
                </text>
              </g>
            );
          })}
          {/* X Axis */}
          {gridXData.map((entry: number) => {
            return (
              <g>
                <line
                  key={`x-line-${entry}`}
                  x1={scaleX(entry)}
                  y1={chartHeight}
                  x2={scaleX(entry)}
                  y2={chartHeight + gridTickHeight}
                  stroke={colors.blue}
                  strokeWidth={0.5}
                />
                <text
                  key={`x-text-${entry}`}
                  x={scaleX(entry)}
                  y={chartHeight + gridTickHeight + 2}
                  dy={"1em"}
                  textAnchor={"middle"}
                  fontFamily={fonts.mono}
                  fontSize={fontSizes.small}
                  fill={colors.blue}
                >
                  {entry}
                </text>
              </g>
            );
          })}
          {/* Y Axis */}
          {gridYData.map((entry: number) => {
            return (
              <g>
                <line
                  key={`y-line-${entry}`}
                  x1={0}
                  y1={scaleY(entry)}
                  x2={chartWidth}
                  y2={scaleY(entry)}
                  stroke={colors.blue}
                  strokeWidth={0.5}
                />
                <text
                  key={`y-text-${entry}`}
                  x={-4}
                  y={scaleY(entry)}
                  dy={"0.35em"}
                  textAnchor={"end"}
                  fontFamily={fonts.mono}
                  fontSize={fontSizes.small}
                  fill={colors.blue}
                >
                  {entry != 0 && entry}
                </text>
              </g>
            );
          })}
          <g className={`hover-elements-${mode}`} style={{ display: "block" }}>
            <line
              className={`hover-line-${mode}`}
              x1={mode === "geburt" ? scaleX(1903) : scaleX(1944)}
              y1={-gridTickHeight}
              x2={mode === "geburt" ? scaleX(1903) : scaleX(1944)}
              y2={chartHeight + gridTickHeight}
              stroke={colors.blue}
            />
            <circle
              className={`hover-circle-${mode}`}
              cx={mode === "geburt" ? scaleX(1903) : scaleX(1944)}
              cy={mode === "geburt" ? scaleY(41) : scaleY(113)}
              r={4}
              fill={colors.blue}
            />
            <rect
              className={`hover-text-bg-${mode}`}
              x={mode === "geburt" ? scaleX(1903) - 20 : scaleX(1944) - 20}
              y={chartHeight + gridTickHeight + 2}
              width={40}
              height={20}
              fill={colors.backgroundDark}
            />
            <rect
              className={`hover-text-value-bg-${mode}`}
              x={mode === "geburt" ? scaleX(1903) - 45 : scaleX(1944) - 45}
              y={-margins.top}
              width={90}
              height={20}
              fill={colors.backgroundDark}
            />
            <text
              className={`hover-year-${mode}`}
              x={mode === "geburt" ? scaleX(1903) : scaleX(1944)}
              y={chartHeight + gridTickHeight + 2}
              dy={"1em"}
              textAnchor={"middle"}
              fontFamily={fonts.mono}
              fontSize={fontSizes.small}
              fill={colors.blue}
            >
              {mode === "geburt" ? 1903 : 1944}
            </text>
            <text
              className={`hover-value-${mode}`}
              x={mode === "geburt" ? scaleX(1903) : scaleX(1944)}
              y={-margins.top}
              dy={"1em"}
              textAnchor={"middle"}
              fontFamily={fonts.mono}
              fontSize={fontSizes.small}
              fill={colors.blue}
            >
              {mode === "geburt" ? "41 Tafel(n)" : "113 Tafel(n)"}
            </text>
          </g>
        </g>
      </g>
    );
  };

  return (
    <ChartContainer ref={updateSize}>
      <svg id={"line-chart"} width={width} height={width * 0.6}>
        {dataGeburt && dataTod && createChart(mode)}
      </svg>
    </ChartContainer>
  );
};

export default LineChart;

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import useMeasure from "react-use-measure";
import * as d3 from "d3";

import { colors, strokes, sizes } from "../../models";

const MapContainer = styled.div`
  margin: ${sizes.large}px 0 0 0;
`;

const Map = ({ data }: any) => {
  const [berlin, setBerlin] = useState<any>(null);
  const [updateSize, bounds] = useMeasure();
  const width: number = bounds.width;
  const height: number = bounds.height;

  const projection = d3
    .geoConicConformal()
    .fitSize([width - 20, height - 30], berlin);

  useEffect(() => {
    fetch("berlin.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setBerlin(data);
      });
  }, []);

  const createMap = (berlinData: any) => {
    const path: any = d3.geoPath().projection(projection);
    const berlinPath = berlinData.features.map((d: any, i: number) => {
      return (
        <path
          key={`map-path-${i}`}
          d={path(d)}
          fill={"none"}
          stroke={colors.blue}
          strokeWidth={0.75}
        />
      );
    });
    return <g>{berlinPath}</g>;
  };

  const createBubble = (bubbleData: any) => {
    const radius = 5;

    return (
      <circle
        cx={projection([bubbleData.latitude, bubbleData.longitude])![0]}
        cy={projection([bubbleData.latitude, bubbleData.longitude])![1]}
        r={radius}
        fill={colors.blue}
        stroke={colors.blue}
      />
    );
  };

  return (
    <MapContainer ref={updateSize}>
      <svg width={width} height={width * 0.6}>
        {berlin && createMap(berlin)}
        {data && createBubble(data)}
      </svg>
    </MapContainer>
  );
};

export default Map;

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { H2, Body, colors, breakpoints, sizes } from "../../models";
import { RectChart, Legend, ExploreTool, LineChart } from "../graphic";
import {
  GridContainer,
  GraphicLeftWrapper,
  RightTextWrapper,
  ScreenHeight,
  BigDistance,
} from "../basics";
import { MainText } from "../content";
import { useIntersection } from "../../hooks";

const MainSec = styled.main`
  background: ${colors.backgroundDark};
`;

const GraphicSizer = styled.div`
  height: 100%;
`;

const MainTextContainer = styled.div`
  background: ${colors.backgroundDark};
  padding: ${sizes.fine}px ${sizes.petite}px ${sizes.fine}px ${sizes.petite}px;
  @media (min-width: ${breakpoints.desktopS}px) {
    padding: 0;
  }
`;

const TextHeadline = styled.div`
  ${H2};
`;

const BodyText = styled.div`
  ${Body};
`;

const MainSection = ({ step, order, setGlobalStep, setOrder }: any) => {
  const [exploreTool, setExploreTool] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [data, setData] = useState<any | null>(null);
  const graphicPosition = useRef(null);
  const scrollerContainerRef = useRef(null);

  useIntersection(
    scrollerContainerRef,
    "#waypoint",
    (entry: any) => {
      if (entry.intersectionRatio === 1) {
        setGlobalStep(entry.target.dataset.step);
        setOrder(entry.target.dataset.order);
        //   setChecked(JSON.parse(entry.target.dataset.toggle));
      }
    },
    { threshold: 1, rootMargin: "0px" }
  );

  useEffect(() => {
    setGlobalStep(0);
  }, []);

  const handleExploreTool = (exploreToolInput: boolean) => {
    setExploreTool(exploreToolInput);
  };

  const handleSelectedData = (selectedDataInput: any) => {
    setSelectedData(selectedDataInput);
  };

  const handleData = (dataInput: any) => {
    setData(dataInput);
  };

  const createMainText = () => {
    return (
      <>
        {MainText.map((entry, index) => {
          return (
            <>
              <MainTextContainer>
                <span
                  key={`main-span-${index}`}
                  id="waypoint"
                  data-step={entry.step}
                  data-order={entry.order}
                ></span>

                {entry.headline && (
                  <TextHeadline>{entry.headline}</TextHeadline>
                )}
                <BodyText>{entry.text}</BodyText>
                {entry.order === "geburt" && <LineChart mode={"geburt"} />}
                {entry.order === "tod" && <LineChart mode={"tod"} />}

                <span
                  key={`main-span1-${index}`}
                  id="waypoint"
                  data-step={entry.step}
                  data-order={entry.order}
                ></span>
              </MainTextContainer>
              <ScreenHeight />
            </>
          );
        })}
      </>
    );
  };
  return (
    <MainSec>
      <GridContainer>
        <GraphicLeftWrapper>
          <Legend order={order} />
          <GraphicSizer ref={graphicPosition}>
            <RectChart
              step={step}
              order={order}
              data={data}
              containerPosition={graphicPosition}
              setExploreTool={handleExploreTool}
              setSelectedData={handleSelectedData}
              setData={handleData}
            />
          </GraphicSizer>
        </GraphicLeftWrapper>
        <RightTextWrapper ref={scrollerContainerRef}>
          <ScreenHeight />
          {MainText && createMainText()}
        </RightTextWrapper>
        <ExploreTool
          exploreMode={exploreTool}
          selectedData={selectedData}
          setExploreTool={handleExploreTool}
          step={step}
        />
      </GridContainer>
      <BigDistance />
    </MainSec>
  );
};

export default MainSection;

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { colors, Body, legend } from "../../models";

const OrderLegend = styled.div`
  position: absolute;
  ${legend};
  padding: 5px;
`;

const CoverText = styled.div`
  ${Body};
  background: ${colors.backgroundDark};
  padding: 15px 20px;
`;

const Legend = ({ order }: any) => {
  const [coverVisible, setCoverVisible] = useState<boolean>(false);

  const setInvisble = () => {
    setCoverVisible(false);
  };

  useEffect(() => {
    setCoverVisible(true);
    setTimeout(setInvisble, 1000);
  }, [order]);

  const returnOrderName = (order: string) => {
    if (order === "alphabet") {
      return "Alphabet";
    } else if (order === "person") {
      return "Geschlecht von Personen";
    } else if (order === "geburt") {
      return "Geburtsjahren von Personen";
    } else if (order === "tod") {
      return "Todesjahren von Personen";
    }
  };

  return <OrderLegend>Sortiert nach {returnOrderName(order)}</OrderLegend>;
};

export default Legend;

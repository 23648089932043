import * as React from "react";
import styled from "styled-components";
import {
  H2,
  bigBody,
  H4,
  subline,
  sublineHeadline,
  sizes,
  colors,
  footerLeftGrid,
  footerRightGrid,
} from "../../models";
import { BigDistance, GridContainer, MidTextWrapper } from "../basics";
import { ReactComponent as FhpLogo } from "../../svg/FHP_logo_white.svg";
import { ExternalLink } from "../basics";

const FooterSectionWrapper = styled.div`
  width: 100%;
  background: ${colors.blue};
  box-shadow: inset 1px 15px 20px -14px rgba(0, 0, 0, 0.5); ;
`;

const MethodSectionWrapper = styled.div`
  background: ${colors.backgroundDark};
  padding-bottom: ${sizes.extremehuge}px;
`;

const TextHeadline = styled.div`
  ${H2};
`;

const MethodText = styled.div`
  ${bigBody}
`;

const Sources = styled.div`
  ${footerLeftGrid};
  ${subline};
  color: white;
  padding: ${sizes.hefty}px 0 ${sizes.wide}px 0;
  max-width: 400px;
`;

const Contact = styled.div`
  ${footerRightGrid};
  ${subline};
  color: white;
  padding: ${sizes.hefty}px 0 ${sizes.wide}px 0;
`;

const FooterElement = styled.div`
  margin-bottom: ${sizes.tiny}px;
`;

const FooterHeadline = styled.div`
  ${sublineHeadline};
  color: white;
`;

const FooterLink = styled.a`
  color: ${colors.white};
  text-decoration: none;
  border-bottom: 1px solid ${colors.white};

  &:hover {
    border-bottom: none;
  }
`;

const LogoWrapper = styled.div`
  max-width: 250px;
  margin-top: ${sizes.hefty}px;
  margin-bottom: -10px;
`;

const FooterSection = () => {
  return (
    <>
      <MethodSectionWrapper>
        <GridContainer>
          <MidTextWrapper>
            <TextHeadline>Methodik</TextHeadline>
            <MethodText>
              <p>
                Die verwendeten Daten stammen von Open Data Berlin und wurden
                2016 veröffentlicht. Die Erhebung dieser Daten ist{" "}
                <ExternalLink
                  href="https://www.gedenktafeln-in-berlin.de/projektinfo"
                  target="_blank"
                >
                  hier
                </ExternalLink>{" "}
                dokumentiert. Informationen über Geschlecht, Geburts- und
                Todesjahr von Personen wurden auf Basis der Titel der Tafeln von
                Wikipedia Artikeln gescraped. Das automatisierte Scraping wurde
                zusätzlich manuell überprüft. Dennoch ist es möglich, dass
                Personen falsch zugewiesen wurden (z.B. wenn eine eher
                unbekannte Person mit einer Gedenktafel einen Namensvetter mit
                Wikipedia-Artikel hat).
              </p>
              <p>
                Da es keine Kategorie “Person” in den Wikipedia Artikel
                Meta-Daten gibt, dienten die Kategorien “Frau” und “Mann” dazu
                Personen zu identifizieren. Daher gibt es auch keine
                Kategorisierung von non-binären Personen in der Visualisierung.
                Personen ohne Wikipedia-Artikel wurden abhängig vom
                entsprechenden Eintrag auf{" "}
                <ExternalLink
                  href="https://www.gedenktafeln-in-berlin.de/"
                  target="_blank"
                >
                  gedenktafeln-in-berlin.de
                </ExternalLink>{" "}
                manuell Geschlecht, Geburts- und Todesjahr zugewiesen.
              </p>
              <p>
                Teilweise gibt es im Datensatz Titel von Gedenktafeln- und
                zeichen, die einen Namen beinhalten, jedoch nicht explizit
                dieser Person erinnern (z.B. “Christophorus-Kirche”). Diese
                Fälle wurden nicht als Personen klassifiziert. Andere Fälle, bei
                denen die Personen klar Hauptprotagonist*innen der Tafeln sind,
                wurden als Personen eingeordnet (z.B. “Ermordung von Karl
                Liebknecht und Rosa Luxemburg”).
              </p>
            </MethodText>
          </MidTextWrapper>
        </GridContainer>
      </MethodSectionWrapper>
      <FooterSectionWrapper>
        <GridContainer>
          <Sources>
            <FooterHeadline>Quellen:</FooterHeadline>
            <FooterElement>
              <FooterLink
                href="https://daten.berlin.de/datensaetze/liste-der-gedenktafeln-berlin"
                target="_blank"
              >
                daten.berlin.de (Stand: 7.7.2022)
              </FooterLink>
            </FooterElement>
            <FooterElement>
              <FooterLink
                href="https://www.gedenktafeln-in-berlin.de/"
                target="_blank"
              >
                gedenktafeln-in-berlin.de (Stand: 7.7.2022)
              </FooterLink>
            </FooterElement>
            <FooterElement>
              <FooterLink
                href="https://de.wikipedia.org/wiki/Wikipedia:Hauptseite"
                target="_blank"
              >
                de.wikipedia.org (Stand: 28.6.2022)
              </FooterLink>
            </FooterElement>
            <br />
            <FooterHeadline>Bildnachweise:</FooterHeadline>
            <FooterElement>
              <FooterLink
                href="https://de.m.wikipedia.org/wiki/Datei:Berliner_Gedenktafel_Pariser_Platz_6a_%28Mitte%29_Giacomo_Meyerbeer.jpg"
                target="_blank"
              >
                Berliner Gedenktafel, Giacomo Meyerbeer, Pariser Platz 6a,
                Berlin-Mitte, Deutschland OTFW, Berlin (CC BY-SA 3.0)
              </FooterLink>
            </FooterElement>
            <FooterElement>
              <FooterLink
                href="https://commons.wikimedia.org/wiki/File:Gedenktafel_An_der_Wuhlheide_263_%28K%C3%B6pen%29_Fussball_Route_Berlin_1_FC_Union_Berlin4.jpg"
                target="_blank"
              >
                Gedenktafel, Fussball Route Berlin, 1. FC Union Berlin, An der
                Wuhlheide 263, Berlin-Köpenick, Deutschland OTFW, Berlin (CC
                BY-SA 3.0)
              </FooterLink>
            </FooterElement>
          </Sources>
          <Contact>
            Konzept, Datenaufbereitung, Gestaltung und Programmierung von{" "}
            <FooterLink href="https://honigmitoe.de/" target="_blank">
              Tim&nbsp;Hönig
            </FooterLink>
            <LogoWrapper>
              <FhpLogo style={{ maxWidth: "100%" }} />
            </LogoWrapper>
            Das Projekt entstand im Seminar “Citizen Science - Stadtdaten
            visualisieren” bei Lucas&nbsp;Vogel
          </Contact>
        </GridContainer>
      </FooterSectionWrapper>
    </>
  );
};

export default FooterSection;

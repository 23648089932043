import React from "react";
import styled from "styled-components";
import {
  colors,
  sizes,
  breakpoints,
  tooltipHeadline,
  tooltipContent,
} from "../../models";

const TooltipContainer = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 100;
`;

const TooltipBox = styled.div<any>`
  backdrop-filter: blur(3px);
  position: absolute;
  background: ${colors.whiteTransparent};
  padding: ${sizes.fine}px ${sizes.base}px ${sizes.fine}px ${sizes.base}px;
  border-radius: 5px;
  display: none;
  width: max-content;
  text-align: center;
  width: 160px;
  transform: ${(props) =>
    props.alignment === "above"
      ? `translate(-50%, calc(-100% - ${sizes.base}px))`
      : `translate(-50%, calc(${sizes.junior}px))`};
  hyphens: auto;
  hyphenate-limit-lines: 1;
`;

const Arrow = styled.div<any>`
  backdrop-filter: blur(3px);
  position: absolute;
  top: ${(props) => (props.alignment === "above" ? "auto" : "-10px")};
  bottom: ${(props) => (props.alignment === "above" ? "-10px" : "auto")};
  border-width: 5px;
  border-style: solid;
  border-color: ${(props) =>
    props.alignment === "above"
      ? `${colors.whiteTransparent} transparent transparent transparent`
      : `transparent transparent ${colors.whiteTransparent} transparent`};
`;

const TooltipContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipHeadline = styled.div`
  ${tooltipHeadline};
`;

const TooltipContent = styled.span`
  ${tooltipContent};
  margin-top: ${sizes.micro}px;
`;

export const Tooltip = ({
  text,
  show,
  position,
  alignment,
}: {
  text: string;
  show: boolean;
  position: any;
  alignment: string;
}) => {
  const preventOverlappingX = (x: number) => {
    if (x < 90) {
      return 90;
    } else {
      return x;
    }
  };

  const calculateArrowPosition = (x: number) => {
    if (x < 90) {
      return x;
    } else {
      return 93;
    }
  };

  return (
    <TooltipContainer
      style={{ left: `${preventOverlappingX(position.x)}px`, top: position.y }}
    >
      <TooltipBox
        style={show ? { display: "block" } : { display: "none" }}
        alignment={alignment}
      >
        {text}
        <Arrow
          alignment={alignment}
          style={{ left: `${calculateArrowPosition(position.x)}px` }}
        />
      </TooltipBox>
    </TooltipContainer>
  );
};

export const setRectChartTooltipContent = (data: any) => {
  return (
    <TooltipContentContainer>
      <TooltipHeadline>{data}</TooltipHeadline>
    </TooltipContentContainer>
  );
};

export const setCloroMapTooltipContent = (headline: string, value: number) => {
  return (
    <TooltipContentContainer>
      <TooltipHeadline>{headline}</TooltipHeadline>
      <TooltipContent>{value} Tafeln/Zeichen</TooltipContent>
    </TooltipContentContainer>
  );
};

import React from "react";
import styled from "styled-components";
import {
  H2,
  Body,
  sizes,
  colors,
  subline,
  sublineHeadline,
  breakpoints,
} from "../../models";
import giacomo from "../../images/giacomo_meyerbeer.jpg";
import fussballroute from "../../images/fussballroute.jpg";
import { ExternalLink } from "../basics";

const Image = styled.img`
  width: 100%;
  margin-bottom: ${sizes.fine}px;
`;

const ImageSublineHeadline = styled.div`
  ${sublineHeadline};
`;

const ImageSubline = styled.a`
  ${subline};
`;

const HighlightBlue = styled.span`
  background-color: ${colors.blue};
  padding: 0 5px 2px 5px;
  color: ${colors.backgroundDark};
`;

const HighlightFemale = styled.span`
  background-color: ${colors.female};
  padding: 0 5px 2px 5px;
  color: ${colors.backgroundDark};
`;

const HighlightMale = styled.span`
  background-color: ${colors.male};
  padding: 0 5px 2px 5px;
  color: ${colors.backgroundDark};
`;

const HighlightBothGender = styled.span`
  background-color: ${colors.bothGender};
  padding: 0 5px 2px 5px;
  color: ${colors.backgroundDark};
`;

const Paragraph = styled.p`
  hyphens: manual;
  pointer-events: auto;
  @media (min-width: ${breakpoints.tabletL}px) {
    margin: 0 0 ${sizes.hefty}px 0;
  }
`;

const ParagraphSmallSpace = styled.p`
  ${Body};
  pointer-events: auto;
  margin: 0 0 ${sizes.petite}px 0;
`;

export const MainText = [
  {
    step: 0.5,
    order: "alphabet",
    headline: "",
    text: (
      <Paragraph>
        Jedes graue Rechteck repräsentiert eine Gedenktafel/ein Gedenkzeichen in
        Berlin. Während der gesamten Geschichte kannst du einzelne Tafeln
        auswählen um mehr über sie zu erfahren.
      </Paragraph>
    ),
  },
  {
    step: 1,
    order: "alphabet",
    headline: "Die Erste",
    text: (
      <>
        <Paragraph>
          Die erste Gedenktafel deren Errichtungsjahr dokumentiert ist, stammt
          aus dem Jahr 1890. Es handelt sich dabei um eine Bronzetafel zu Ehren
          von <HighlightBlue>Giacomo Meyerbeer (1791-1864),</HighlightBlue> dem
          Komponisten und Generalmusikdirektor der Königlichen Oper. Die
          Originaltafel wurde von den Nazis vernichtet, jedoch gibt es seit 2013
          eine neue Tafel am gleichen Ort.
        </Paragraph>
        <Image src={giacomo} />
        <ImageSublineHeadline>
          Neue Tafel zum Gedenken an Giacomo Meyerbeer (2013).
        </ImageSublineHeadline>
      </>
    ),
  },
  {
    step: 2,
    order: "alphabet",
    headline: "Häufigste Titel",
    text: (
      <Paragraph>
        Einige Tafeln adressieren das selbe Ereignis oder die selben Personen.
        Im Gedenken an{" "}
        <HighlightBlue>
          “Jüdische Hausbewohnerinnen und -bewohner”
        </HighlightBlue>{" "}
        wurden alleine 18 Tafeln errichtet. Sie befinden sich an den Häusern, in
        denen Jüd*innen gewohnt haben, die von den Nazis verfolgt und in
        Konzentrations- und Vernichtungslager deportiert wurden. Teilweise
        werden die Bewohner*innen zusätzlich namentlich auf den Tafeln erwähnt.
      </Paragraph>
    ),
  },
  {
    step: 3,
    order: "alphabet",
    headline: "Nationalsozialismus",
    text: (
      <Paragraph>
        Die Zeit von Nazi-Deutschland hat einen sehr großen Einfluss auf die
        Berliner Erinnerungskultur. Das spiegeln 603&nbsp;Tafeln wieder, die
        einen einen{" "}
        <HighlightBlue>Bezug zum National&shy;sozialismus</HighlightBlue>{" "}
        vorweisen. Dabei stehen vor allem die verfolgten Personen und
        Personengruppen des Regimes im Fokus. Aber auch die
        Wiederstandstkämpfer*innen und -gruppen sind mit vielen Tafeln
        vertreten.
      </Paragraph>
    ),
  },
  {
    step: 4,
    order: "alphabet",
    headline: "Fußball in Berlin",
    text: (
      <>
        <Paragraph>
          Einige der Tafeln und Zeichen wurden in thematischen Gruppen zusammen
          konzipiert und errichtet. Dazu gehören auch{" "}
          <HighlightBlue>drei Fußballrouten,</HighlightBlue> die die Historie
          des Sports in der Hauptstadt erlebbar machen.{" "}
        </Paragraph>
        <Image src={fussballroute} />
        <ImageSublineHeadline>
          Teil der ersten Fußballroute.
        </ImageSublineHeadline>
      </>
    ),
  },
  {
    step: 5,
    order: "alphabet",
    headline: "Entfernte Tafeln",
    text: (
      <Paragraph>
        Über 300 Tafeln wurden im Laufe der Jahre wieder entfernt. Das
        Verschwinden einer Tafel kann unterschiedliche Gründe haben. Zum Teil
        wurden sie aus Versehen zerstört, geklaut oder nach der
        Wiedervereinigung demontiert. Klicke auf eine der Tafeln um mehr über
        sie zu erfahren. Häufig findest du den Grund des Verschwindens in der
        Beschreibung.
      </Paragraph>
    ),
  },
  {
    step: 6,
    order: "person",
    headline: "Personen",
    text: (
      <Paragraph>
        Über die Hälfte der Tafeln richten sich im Titel an Personen. Meist wird
        einer einzelnen Person erinnert, wobei teilweise auch Ehepaare, Familien
        oder Gruppen zusammen genannt werden.
      </Paragraph>
    ),
  },
  {
    step: 7,
    order: "person",
    headline: "Geschlechter",
    text: (
      <Paragraph>
        Nur ca. 13% der Tafeln, welche Personen im Titel erwähnen, nennen dabei{" "}
        <HighlightFemale>ausschließlich Frauen.</HighlightFemale> Ca. 84% nennen{" "}
        <HighlightMale>ausschließlich Männer</HighlightMale> und ca. 3% erwähnen{" "}
        <HighlightBothGender>
          sowohl Frauen als auch Männer.
        </HighlightBothGender>{" "}
        Eine Aufschlüsselung über non-binäre Personen ist mit den
        zugrundeliegenden Daten leider nicht möglich.
      </Paragraph>
    ),
  },
  {
    step: 8,
    order: "person",
    headline: "Dauergäste",
    text: (
      <Paragraph>
        Mit 8 Tafeln ist <HighlightMale>Albert Einstein</HighlightMale> die am
        häufigsten vertretene Person.{" "}
        <HighlightFemale>Rosa Luxemburg</HighlightFemale> ist mit vier Tafeln
        die meist vertretene Frau. Sie wird außerdem noch zwei mal zusammen mit
        Karl Liebknecht erwähnt. Sowohl Werner Seelenbinder, als auch Carl von
        Ossietzky, Dietrich Bonnhoeffer und Bernhard Lichtenberg erhielten
        jeweils 6 Tafeln.
      </Paragraph>
    ),
  },
  {
    step: 9,
    order: "geburt",
    headline: "Geburtsdaten",
    text: (
      <>
        <Paragraph>
          Im Jahr 1415 wurde Friedrich III. geboren und ist damit die älteste
          Person mit eigener Gedenktafel. Die meisten Personen, die eine
          Gedenktafel erhalten haben, wurden Ende des 19. Jahrhunderts oder
          Anfang des 20. Jahrhunderts geboren.
        </Paragraph>
      </>
    ),
  },
  {
    step: 10,
    order: "tod",
    headline: "Todesdaten",
    text: (
      <Paragraph>
        Sehr viele der Tafeln erinnern an Personen, die 1944 oder 1945 gestorben
        sind. Dazu gehören vor allem Jüdinnen und Juden, Kommunist*innen und
        andere von den Nazis verfolgte und ermordete Personen.
      </Paragraph>
    ),
  },
  {
    step: 11,
    order: "alphabet",
    headline: "Fazit & Mitmachen",
    text: (
      <>
        <ParagraphSmallSpace>
          Ein Großteil der Gedenktafeln und -zeichen in Berlin steht im direkten
          Zusammenhang mit der Zeit des Nationalsozialismus. Das bestätigen auch
          die Sterbedaten der geehrten Personen, die zum Ende des zweiten
          Weltkriegs ihren Höhepunkt erreichen. Aber auch die Häufigkeit der
          Tafel “Jüdische Hausbewohnerinnen und -bewohner” sprechen eine
          Eindeutige Sprache. Zu anderen Themen, die auf mehreren Tafeln
          aufgegriffen werden, gehören die Opfer der Berliner Mauer, die
          Märzrevolution 1848, sowie die Fussballrouten. Betrachtet man die
          Tafeln zu Ehren von Personen, so sieht man, dass viel mehr Männer als
          Frauen eine Tafel erhielten.
        </ParagraphSmallSpace>
        <ParagraphSmallSpace>
          Wenn du andere Thematiken und Personen ins Gedächtnis Berlin rufen
          möchtest, hast du die Möglichkeit einen Vorschlag für eine neue Tafel
          einzureichen. Pro Jahr werden von der Königliche Porzellan-Manufaktur
          Berlin (KPM) acht neue Tafeln produziert, die in Berlin angebracht
          werden. Unter{" "}
          <ExternalLink
            href="https://www.gedenktafeln-in-berlin.de/gedenktafeln/gedenktafel-vorschlagen"
            target="_blank"
          >
            diesem Link
          </ExternalLink>{" "}
          erhältst du alle Infos dazu wie du Vorschläge einreichen kannst und
          wie Auswahl und Genehmigung der Gedenktafeln ablaufen.
        </ParagraphSmallSpace>
      </>
    ),
  },
];

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import useMeasure from "react-use-measure";
import { Tooltip, setCloroMapTooltipContent } from "./tooltip";
import * as d3 from "d3";

import {
  colors,
  strokes,
  sizes,
  fonts,
  fontSizes,
  breakpoints,
} from "../../models";

const MapContainer = styled.div`
  margin: ${sizes.large}px 0 0 0;
`;

const CloroMap = () => {
  const [berlin, setBerlin] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [tooltip, setTooltip] = useState<any>({
    show: false,
    x: 0,
    y: 0,
    text: undefined,
    alignment: "",
  });
  const [updateSize, bounds] = useMeasure();
  const width: number = bounds.width;
  const height: number = bounds.height;

  const projection = d3
    .geoConicConformal()
    .fitSize([width - 20, height - 65], berlin);

  useEffect(() => {
    fetch("berlin.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setBerlin(data);
      });
  }, []);

  useEffect(() => {
    fetch("cloro-data.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data);
      });
  }, []);

  const handleMouseOver = (
    event: any,
    data: any,
    currentAreaValue: number,
    i: number
  ) => {
    d3.select(`#stroke-${i}`).attr("stroke-opacity", 1);
    setTooltip({
      show: true,
      x: event.clientX,
      y: event.pageY,
      text: setCloroMapTooltipContent(data.properties.name, currentAreaValue),
      alignment: event.clientY < 200 ? "below" : "above",
    });
  };

  const handleMouseOut = (event: any) => {
    d3.selectAll(".bezirk-stroke").attr("stroke-opacity", 0);
    setTooltip({
      show: false,
      x: 0,
      y: 0,
      text: undefined,
      alignment: "",
    });
  };

  const createMap = (berlinData: any, tafelData: any) => {
    const scaleColor = d3
      .scaleLinear<string>()
      .domain([0, 1000])
      .range([colors.white, colors.darkBlue]);
    const path: any = d3.geoPath().projection(projection);
    const legendWidth = width >= breakpoints.tabletL ? 300 : 250;
    const legendHeight = 12;
    const legendY = 22;

    const berlinPath = berlinData.features.map((d: any, i: number) => {
      const currentArea: string = d.properties.name;
      console.log(tafelData);
      const currentAreaValue: number = tafelData[currentArea];
      const currentColor: string = scaleColor(currentAreaValue);
      return (
        <path
          className={"bezirk"}
          key={`clor-map-path-${i}`}
          d={path(d)}
          fill={currentColor}
          stroke={colors.blue}
          strokeWidth={0.75}
          onMouseMove={(e) => {
            handleMouseOver(e, d, currentAreaValue, i);
          }}
          onMouseOut={handleMouseOut}
        />
      );
    });

    const berlinStrokePath = berlinData.features.map((d: any, i: number) => {
      return (
        <path
          id={`stroke-${i}`}
          className={"bezirk-stroke"}
          key={`clor-map-path-stroke-${i}`}
          d={path(d)}
          fill={"none"}
          stroke={colors.white}
          strokeWidth={2}
          strokeOpacity={0}
        />
      );
    });

    return (
      <g>
        <defs>
          <linearGradient
            id={"grad1"}
            x1={"0%"}
            y1={"0%"}
            x2={"100%"}
            y2={"0%"}
          >
            <stop
              offset={"0%"}
              style={{ stopColor: "white", stopOpacity: 1 }}
            />
            <stop
              offset={"100%"}
              style={{ stopColor: colors.darkBlue, stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <rect
          x={width / 2 - legendWidth / 2}
          y={legendY}
          width={legendWidth}
          height={legendHeight}
          fill={"url(#grad1)"}
          stroke={colors.blue}
          strokeWidth={0.75}
        />
        <line
          x1={width / 2 - legendWidth / 2}
          y1={legendY + legendHeight}
          x2={width / 2 - legendWidth / 2}
          y2={legendY + legendHeight + 5}
          stroke={colors.blue}
          strokeWidth={0.75}
        ></line>
        <text
          x={width / 2 - legendWidth / 2}
          y={legendY + legendHeight + 5}
          dy={"1em"}
          fontFamily={fonts.mono}
          fontSize={fontSizes.small}
          textAnchor={"middle"}
          fill={colors.blue}
        >
          0
        </text>
        <line
          x1={width / 2 + legendWidth / 2}
          y1={legendY + legendHeight}
          x2={width / 2 + legendWidth / 2}
          y2={legendY + legendHeight + 5}
          stroke={colors.blue}
          strokeWidth={0.75}
        ></line>
        <text
          x={width / 2 + legendWidth / 2}
          y={legendY + legendHeight + 5}
          dy={"1em"}
          fontFamily={fonts.mono}
          fontSize={fontSizes.small}
          textAnchor={"middle"}
          fill={colors.blue}
        >
          1000
        </text>
        <text
          x={width / 2}
          y={0}
          dy={"1em"}
          fontFamily={fonts.mono}
          fontSize={fontSizes.small}
          textAnchor={"middle"}
          fill={colors.blue}
        >
          Anzahl an Tafeln/Zeichen:
        </text>
        <g style={{ transform: "translate(0,55px)" }}>
          {berlinPath}
          {berlinStrokePath}
        </g>
      </g>
    );
  };

  return (
    <MapContainer ref={updateSize}>
      <svg width={width} height={width >= 500 ? width * 0.7 : width}>
        {berlin && data && createMap(berlin, data)}
      </svg>
      <Tooltip
        text={tooltip.text}
        show={tooltip.show}
        position={{ x: tooltip.x, y: tooltip.y }}
        alignment={tooltip.alignment}
      />
    </MapContainer>
  );
};

export default CloroMap;

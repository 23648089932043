import { css } from "styled-components";
import { breakpoints, gridMargin, gutterWidths, sizes } from ".";

const gridTextSettings = css`
  z-index: 5;
`;

export const gridContainer = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: ${gutterWidths.smartphone}px;
  padding: 0 ${gridMargin.smartphone}px 0 ${gridMargin.smartphone}px;
  max-width: 1920px;
  margin: auto;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${gutterWidths.tabletL}px;
    padding: 0 ${gridMargin.tabletL}px 0 ${gridMargin.tabletL}px;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-gap: ${gutterWidths.desktopS}px;
    padding: 0 ${gridMargin.desktopS}px 0 ${gridMargin.desktopS}px;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-gap: ${gutterWidths.desktopL}px;
    padding: 0 ${gridMargin.desktopL}px 0 ${gridMargin.desktopL}px;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-gap: ${gutterWidths.desktopXL}px;
    padding: 0 ${gridMargin.desktopXL}px 0 ${gridMargin.desktopXL}px;
  }
`;

export const textRightGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 3 / 11;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 9 / 13;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 9 / 13;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 9 / 13;
  }
`;

export const textExploreToolRightGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 9 / 13;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 9 / 13;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 9 / 13;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 9 / 13;
  }
`;

export const textMidGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 2 / 12;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 3 / 11;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 4 / 10;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 4 / 10;
  }
`;

export const textHeroGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 1 / 13;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 2 / 12;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 2 / 12;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 2 / 12;
  }
`;

export const graphicLeftGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 1 / 13;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 1 / 9;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 1 / 9;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 1 / 9;
  }
`;

export const footerLeftGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 1 / 7;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 2 / 9;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 2 / 9;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 2 / 9;
  }
`;

export const footerRightGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 8 / 13;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 9 / 12;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 9 / 12;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 9 / 12;
  }
`;

export const Wrapper = css`
  padding: 0 ${gridMargin.smartphone * 2}px;
  @media (min-width: ${breakpoints.tabletL}px) {
    padding: 0 ${gridMargin.tabletL * 2}px;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    padding: 0 ${gridMargin.desktopS * 2}px;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    padding: 0 ${gridMargin.desktopL * 2}px;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    padding: 0 ${gridMargin.desktopXL * 2}px;
  }
`;

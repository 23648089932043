import { css } from "styled-components";
import {
  fonts,
  colors,
  fontSizes,
  strokes,
  breakpoints,
  lineHeights,
} from "../models";
import { sizes } from "./constants";

export const baseFontProps = {
  "font-family": fonts.base,
  "font-weight": fonts.weightMedium,
  "font-size": fontSizes.base,
  color: colors.blue,
  "line-height": `${lineHeights.body}`,
};

export const monoFontProps = {
  "font-family": fonts.mono,
  "font-weight": fonts.weightRegular,
  "font-size": fontSizes.base,
  color: colors.blue,
  "line-height": `${lineHeights.body}`,
};

// -03 macro
export const macroRegular = {
  ...baseFontProps,
  "font-size": fontSizes.macro,
};

// -02 micro
export const microRegular = {
  ...baseFontProps,
  "font-size": fontSizes.micro,
};

// -01 small
export const smallRegular = {
  ...baseFontProps,
  "font-size": fontSizes.small,
};

export const smallMono = {
  ...monoFontProps,
  "font-size": fontSizes.small,
};

// 00 base
export const baseRegular = {
  ...baseFontProps,
};

export const baseMono = {
  ...monoFontProps,
};

// +01 midSize
export const midSizeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.midSize,
};

// +02 big
export const bigRegular = {
  ...baseFontProps,
  "font-size": fontSizes.big,
};

// +03 large
export const largeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.large,
};

// +04 huge
export const hugeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.huge,
  "line-height": "1.2em",
};

// +05 gigantic
export const giganticRegular = {
  ...baseFontProps,
  "font-size": fontSizes.gigantic,
  "line-height": "1.2em",
};

// +06 mega
export const megaRegular = {
  ...baseFontProps,
  "font-size": fontSizes.mega,
  "line-height": "1em",
};

// STORY
/*
  {
    macro: "8.192px",
    micro: "10.24px",
    small: "12.8px",
    base: "16px",
    midSize: "20px",
    big: "25px",
    large: "31.25px",
    huge: "39.0625px",
    gigantic: "48.828125px"
    mega: "61.04px"
  }
*/

export const Body = css`
  ${baseRegular};
  @media (min-width: ${breakpoints.desktopS}px) {
    padding-right: ${sizes.twice}px;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${midSizeRegular};
  }
`;

export const bigBody = css`
  ${baseRegular};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${midSizeRegular}
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${bigRegular};
  }
`;

export const H4 = css`
  ${baseRegular};
  @media (min-width: ${breakpoints.tabletL}px) {
    ${bigRegular};
  }
`;

export const H2 = css`
  ${largeRegular};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${hugeRegular};
    margin-bottom: 8px;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${giganticRegular};
  }
`;

export const H0 = css`
  ${megaRegular};
  text-transform: uppercase;
  @media (min-width: ${breakpoints.tabletL}px) {
    font-size: 80px;
    line-height: 80px;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    font-size: 120px;
    line-height: 120px;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    font-size: 180px;
    line-height: 180px;
  }
`;

export const tooltipHeadline = css`
  ${baseRegular};
  @media (min-width: ${breakpoints.tabletL}px) {
    ${baseRegular};
  }
`;

export const tooltipContent = css`
  ${smallMono};
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${baseMono};
  }
`;

export const legend = css`
  ${baseMono};
`;

export const sublineHeadline = css`
  ${smallMono};
  font-weight: 600;
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${baseMono};
  }
`;

export const subline = css`
  ${smallMono};
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${baseMono};
  }
`;

import React, { useState, useEffect } from "react";
import useMeasure from "react-use-measure";
import styled, { keyframes } from "styled-components";
import {
  colors,
  sizes,
  Body,
  H2,
  legend,
  sublineHeadline,
  subline,
  gridMargin,
} from "../../models";
import { ExploreToolRightTextWrapper } from "../basics";
import Map from "./map";

const ExploreWrapper = styled.div<any>`
  display: ${(props) => (props.active ? "block" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  background: ${colors.backgroundDark};
  box-shadow: rgba(0, 0, 0, 0.2) -2px 0px 25px;
  transform: ${(props) =>
    props.visible ? "none" : `translate(${props.boxWidth + 30}px, 0)`};
  transition: transform 0.6s ease;
  padding: 20px;
  z-index: 200;
  overflow-y: scroll;
`;

const TextHeadline = styled.div`
  ${H2};
  margin-bottom: ${sizes.petite}px;
`;

const BodyText = styled.div`
  ${Body};
  hyphens: auto;

  hyphenate-limit-lines: 2;
  p {
    margin: 0;
  }
`;

const BackButton = styled.button`
  ${legend};
  border-radius: 0px;
  border: 1px solid ${colors.blue};
  background: ${colors.backgroundDark};
  padding: 5px 12px;
  position: fixed;

  &:hover {
    background: ${colors.backgroundLight};
    cursor: pointer;
  }
`;

const ReadMore = styled.span<any>`
  ${sublineHeadline};
  border-bottom: 1px solid ${colors.blue};
  transition: border-bottom 0.2s ease-out;
  display: ${(props) => (props.visible ? "inline" : "none")};

  &:hover {
    cursor: pointer;
    border-bottom: 0px solid ${colors.blue};
  }
`;

const OptionalText = styled.div`
  ${sublineHeadline};
  margin: ${sizes.fine}px 0 ${sizes.petite}px 0;
`;

const OptionalTextElement = styled.div`
  margin-bottom: ${sizes.dblhair}px;
`;

const OptionalTextData = styled.span`
  ${subline};
`;

const OptionalDescription = ({ data }: any) => {
  const getPersonJahr = (geburtOrTod: string, yearData: any) => {
    const headline = `${geburtOrTod}jahr der Person: `;
    if (typeof yearData === "number") {
      return (
        <OptionalTextElement>
          {headline}
          <OptionalTextData>{yearData}</OptionalTextData>
        </OptionalTextElement>
      );
    } else if (Array.isArray(yearData)) {
      const filteredGeburtsjahre = data.Geburtsjahr.filter(
        (e: any) => e != "none"
      );
      if (filteredGeburtsjahre.length === 1 && filteredGeburtsjahre[0] != 0) {
        return (
          <OptionalTextElement>
            {headline}
            <OptionalTextData>{filteredGeburtsjahre[0]}</OptionalTextData>
          </OptionalTextElement>
        );
      }
    }
  };

  return (
    <>
      <OptionalText>
        <OptionalTextElement>
          Ortsteil: <OptionalTextData>{data.ortsteil}</OptionalTextData>
        </OptionalTextElement>
        <OptionalTextElement>
          Jahr der Errichtung:{" "}
          <OptionalTextData>
            {data.jahr === "none" ? "unbekannt" : data.jahr}
          </OptionalTextData>
        </OptionalTextElement>
        {getPersonJahr("Geburts", data.Geburtsjahr)}
        {getPersonJahr("Todes", data.Todesjahr)}
      </OptionalText>
    </>
  );
};

const ExploreTool = ({
  exploreMode,
  selectedData,
  setExploreTool,
  step,
}: any) => {
  const [updateSize, bounds] = useMeasure();
  const [showReadMore, setShowReadMore] = useState<boolean>(false);
  const [readMoreText, setReadMoreText] = useState<string>("Mehr lesen");
  const [descriptionFull, setDescriptionFull] = useState<boolean>(false);
  const [descriptionText, setDescriptionText] = useState<string>("");

  useEffect(() => {
    document.body.classList.toggle("noscroll", exploreMode);
  }, [exploreMode]);

  const descriptionLong = (description: string) => {
    if (description && description.split(" ").length > 50) {
      return true;
    } else {
      return false;
    }
  };

  const getShortDescription = (description: string) => {
    if (descriptionLong(description)) {
      const shortDescription = description
        .split(" ")
        .slice(0, 50)
        .join(" ")
        .concat(" ... ");
      return shortDescription;
    } else {
      return description.concat(" ");
    }
  };

  useEffect(() => {
    if (!selectedData) return;
    setDescriptionFull(false);
    if (descriptionLong(selectedData.erlauterung)) {
      setReadMoreText("Mehr lesen");
      setShowReadMore(true);
    } else {
      setReadMoreText("Text einklappen");
      setShowReadMore(false);
    }
    setDescriptionText(getShortDescription(selectedData.erlauterung));
  }, [selectedData]);

  const handleClick = () => {
    setExploreTool(false);
  };

  const handleReadMoreClick = () => {
    if (!descriptionFull) {
      setDescriptionText(selectedData.erlauterung.concat(" "));
      setDescriptionFull(true);
      setReadMoreText("Text einklappen");
    } else {
      setDescriptionText(getShortDescription(selectedData.erlauterung));
      setDescriptionFull(false);
      setReadMoreText("Mehr lesen");
    }
  };

  return (
    <ExploreToolRightTextWrapper ref={updateSize}>
      <ExploreWrapper
        boxWidth={bounds.width}
        visible={exploreMode}
        active={step > 0}
      >
        <BackButton onClick={handleClick}>&#10141; Zurück zur Story</BackButton>
        <Map data={selectedData} />
        <TextHeadline>{selectedData && selectedData.Name}</TextHeadline>
        {selectedData && <OptionalDescription data={selectedData} />}
        <BodyText>
          {selectedData && descriptionText}
          <ReadMore onClick={handleReadMoreClick} visible={showReadMore}>
            {readMoreText}
          </ReadMore>
        </BodyText>
      </ExploreWrapper>
    </ExploreToolRightTextWrapper>
  );
};

export default ExploreTool;
